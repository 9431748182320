<template>
  <!-- 客户中心 - 企业招工 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" class="cur-a">岗位登记</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div class="btnBox">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="topAdvertisementInfo('add',{})"
                v-if="userJson.roleId == '1' || userJson.roleId == '-1'|| userJson.roleId == '3' || userJson.roleId =='-6'"
                >新增招聘信息</el-button
              >

              <!--                v-if="topAdvertisement"-->
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="exportList"
                v-if="userJson.roleId == '1' || userJson.roleId == '-1'"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader4"
              
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="招聘岗位"
                align="left"
                prop="recruitmentPost"
                show-overflow-tooltip
                min-width="120"
              />
              <el-table-column
                  label="招聘人数"
                  align="left"
                  prop="recruitmentNum"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                label="工作地点"
                align="left"
                prop="areaNamePath"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                  label="薪酬范围"
                  align="left"
                  prop="salaryRange"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("HR_SALARY_RANGE", scope.row.salaryRange) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="企业名称"
                  align="left"
                  prop="compName"
                  show-overflow-tooltip
                  min-width="200"
              />
              <el-table-column
                  label="联系人"
                  align="left"
                  prop="contactName"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="联系方式"
                  align="left"
                  prop="contactMobile"
                  show-overflow-tooltip
                  min-width="120"
              />
              <el-table-column
                  label="职位有效期"
                  align="left"
                  prop="jobValidityPeriod"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("JOB_VALIDITY_PERIOD", scope.row.jobValidityPeriod) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="到岗时间"
                  align="left"
                  prop="arrivalTime"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("HR_ARRIVAL_TIME", scope.row.arrivalTime) }}
                </template>
              </el-table-column>


              <el-table-column
                label="学历要求"
                align="left"
                prop="education"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("HR_RECRUITMENT_EDUCATION", scope.row.education) }}
                </template>
              </el-table-column>
              <el-table-column
                label="工作经验"
                align="left"
                prop="workExperience"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("WORK_EXPERIENCE", scope.row.workExperience) }}
                </template>
              </el-table-column>
              <el-table-column
                label="年龄要求"
                align="left"
                prop="ageRequirement"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("AGE_REQUIREMENT", scope.row.ageRequirement) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="性别要求"
                  align="left"
                  prop="sex"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("SEX_REQUIREMENT", scope.row.sex) }}
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="岗位职责/要求"
                align="left"
                prop="postResponsibility"
                show-overflow-tooltip
                min-width="200"
              /> -->
              <el-table-column
                label="提交时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="160"
              />
              <el-table-column
                  label="审核状态"
                  align="left"
                  prop="auditState"
                  show-overflow-tooltip
                  min-width="120"
              >
                <template slot-scope="{ row }">
<!--                  10-未审核 20-审核未通过 30-审核通过-->
                  {{ row.auditState&&row.auditState == '10'?'待审核':row.auditState&&row.auditState == '20'?'审核未通过':row.auditState&&row.auditState == '30'?'审核通过':'--' }}
                </template>
                auditState
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="220px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="topAdvertisementInfo('view',scope.row)"
                  >查看</el-button>
<!--                  <el-button-->
<!--                      type="text"-->
<!--                      style="padding:0px 5px"-->
<!--                      size="mini"-->
<!--                      v-if="(!scope.row.isPublish && scope.row.auditState != '30')&&(userJson.roleId == '1' || userJson.roleId == '-1')"-->
<!--                      @click="topAdvertisementInfo('examine',scope.row)"-->
<!--                  >审核</el-button>-->
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      v-if="(!scope.row.isPublish && scope.row.auditState == '20')"
                      @click="topAdvertisementInfo('edit',scope.row)"
                  >编辑</el-button>
<!--                  <el-button-->
<!--                      type="text"-->
<!--                      style="padding:0px 5px"-->
<!--                      size="mini"-->
<!--                      v-if="(!scope.row.isPublish&&scope.row.auditState == '30')&&(userJson.roleId == '1' || userJson.roleId == '-1')"-->
<!--                      @click="changePublish(true,scope.row)"-->
<!--                  >发布</el-button>-->
<!--                  <el-button-->
<!--                      type="text"-->
<!--                      style="padding:0px 5px"-->
<!--                      size="mini"-->
<!--                      v-if="(scope.row.isPublish&&scope.row.auditState == '30')&&(userJson.roleId == '1' || userJson.roleId == '-1')"-->
<!--                      @click="changePublish(false,scope.row)"-->
<!--                  >回收</el-button>-->
<!--                  <el-button-->
<!--                      type="text"-->
<!--                      style="padding:0px 5px"-->
<!--                      size="mini"-->
<!--                      @click="handleLook(scope.row)"-->
<!--                      v-if="userJson.roleId == '1' || userJson.roleId == '-1'"-->
<!--                  >查看申请人（旧）</el-button>-->
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handleApply(scope.row.recruitmentId)"
                  >查看申请人</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import {resetKeepAlive} from "@/utils/common";
export default {
  name: "userFeedback_advertisementList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 顶部广告
      topAdvertisement: false,
      // 认证信息
      authInfo:{}
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  created() {
    // this.getMechanismEnterpriseName();
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      this.doFetch({
        url: "/biz/recruitment/pageList",
        params,
        pageNum,
      });
    },
    // 导出
    exportList() {
      this.$post("/biz/recruitment/export",{pageNum:1,pageSize: 10})
        .then((res) => {
          const arr=[...res.data];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    // 修改发布状态
    changePublish(isPublish,row){
      let params = {
        isPublish,
        recruitmentId: row.recruitmentId
      }
      this.$post("/biz/recruitment/changePublish",params)
          .then((res) => {
            this.$message({
              type: "success",
              message: isPublish?'发布成功':'回收成功',
            });
            this.getData(-1);
          })
          .catch(() => {
            return;
          });
    },
    // 根据登录用户获取该机构的企业名称 - 用户为机构角色调用
    // getMechanismEnterpriseName() {
    //   this.$post("/sys/company/queryCompType").then((res) => {
    //     if (res.status == 0) {
    //       if (res.data.compType == "20") {
    //         this.topAdvertisement = true;
    //       }
    //     }
    //   });
    // },
    // 跳转报名
    topAdvertisementInfo(type,row) {
        this.$post("/hr/auth/info",{}).then(res=>{
            const {userAuthState,compAuthState}=res.data;
            this.authInfo=res.data;
            if(userAuthState==10 && compAuthState==10){
                this.$confirm("您尚未完成认证，请先完成个人认证及企业认证。","温馨提示",{
                    confirmButtonText: '认证',
                    cancelButtonText: '取消',
                    type: 'warning',
                    showClose:false,
                    closeOnClickModal:false
                }).then(()=>{
                    this.goUserAuth();
                });
            }else if(userAuthState==10){
                this.$confirm('您尚未完成个人认证，请完成个人认证。', '温馨提示', {
                    confirmButtonText: '认证',
                    cancelButtonText: '取消',
                    type: 'warning',
                    showClose:false,
                    closeOnClickModal:false
                }).then(() => {
                    this.goUserAuth();
                });
            }else if(compAuthState==10){
                this.$confirm('您尚未完成企业认证，请完成企业认证。', '温馨提示', {
                    confirmButtonText: '认证',
                    cancelButtonText: '取消',
                    type: 'warning',
                    showClose:false,
                    closeOnClickModal:false
                }).then(() => {
                    this.goCompAuth();
                });
            }else if(compAuthState==40){
                this.$confirm(`您的企业认证因（${res.data.compAuthReason}）未通过认证，请重新认证。`, '温馨提示', {
                    confirmButtonText: '认证',
                    cancelButtonText: '取消',
                    type: 'warning',
                    showClose:false,
                    closeOnClickModal:false
                }).then(() => {
                    this.goCompAuth();
                });
            }else if(compAuthState==20){
                this.$message.warning("您的企业认证正在核实中，我们会在三个工作日内审核完成，请耐心等待！");
            }else{
                this.$router.push({
                    path: "/azhr/humanResources/postRegistration/advertisementInfo",
                    query: {
                    type: type,
                    row: row,
                    refresh: false
                    },
                });
            }
        }).catch(e=>{
            console.error("获取认证信息出错",e);
        });
    },
    // 跳转查看申请人
    // handleLook(row) {
    //   console.log(row);
    //   this.$router.push({
    //     path: "/azhr/humanResources/postRegistration/viewContact",
    //     query: {
    //       row: row,
    //       refresh: false
    //     },
    //   });
    // },
    handleApply(id){
      this.$router.push({
        path: "/azhr/humanResources/postRegistration/resumeList",
        query: {
          recruitmentId: id
        },
      });
    },
    // 去往个人认证
    goUserAuth(){
        const {authInfo}=this;
        this.$router.push({
            path:"/azhr/humanResources/personalCertification",
            query:{
                authInfo
            }
        });
    },
    // 去企业认证
    goCompAuth(){
        const {authInfo}=this;
        this.$router.push({
            path:"/azhr/humanResources/enterpriseCertification",
            query:{
                authInfo
            }
        });
    }
  },

  // beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          console.log(1111111111)
          this.getData(-1);

        }
        if (oldVal.query.type) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
  background-color: #eee;
  border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
/deep/.el-pager li{
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
  background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
/deep/.el-pager li.active+li{
  border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
  border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
  //color: #2878FF;
  font-size: 14px!important;
}
/deep/.bgc-bv{
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
/deep/.el-table th.el-table__cell>.cell{
  font-size: 14px!important;
  font-weight: 400!important;
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
  border-color: #2878FF !important;
}
</style>
